export default {
    DATA:[
        {
            isMultiple:false,
            title:'1. 您现在感到疲乏吗？',
            options:['有','没有'],
            type: "select",
            anwser: []
        },
        {
            isMultiple:false,
            title:'2. 您现在所感到的疲乏维持多久了？（分钟、小时、星期、月为维度来填写，例如：3个星期）',
            options:[],
            type: "input",
            anwser: []
        },
        {
            isMultiple:false,
            title:'3. 您现在感到的疲乏，为您带来多大程度的忧虑？',
            options:['0（毫不忧虑）','1','2','3','4','5','6','7','8','9','10（非常忧虑）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'4. 您现在感到的疲乏，有没有妨碍您完成工作或学习活动的能力？影响有多大？',
            options:['0（毫无影响）','1','2','3','4','5','6','7','8','9','10（影响非常大）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'5. 您现在感到的疲乏，有没有妨碍您探望朋友或与朋友的社交活动？影响有多大？',
            options:['0（毫无影响）','1','2','3','4','5','6','7','8','9','10（影响非常大）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'6. 您现在感到的疲乏，有没有妨碍您的性生活？如果有，影响有多大？',
            options:[
                '没有',
                '不适用',
                '0（毫无影响）','1','2','3','4','5','6','7','8','9','10（影响非常大）'
            ],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'7. 总体而言，您现在感到的疲乏，有没有妨碍您做自己喜欢的事情？影响有多大？',
            options:['0（毫无影响）','1','2','3','4','5','6','7','8','9','10（影响非常大）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'8. 您如何形容您现在感到的疲乏？您疲乏的密度和严重性是什么程度？',
            options:['0（轻度）','1','2','3','4','5','6','7','8','9','10（严重）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'9. 您如何形容您现在感到的疲乏？您所感到的疲乏有多大程度是？',
            options:['0（令自己愉快的）','1','2','3','4','5','6','7','8','9','10（令自己不愉快的）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'10. 您如何形容您现在感到的疲乏？您所感到的疲乏有多大程度是？',
            options:['0（并不惹自己讨厌的）','1','2','3','4','5','6','7','8','9','10（惹自己讨厌的）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'11. 您如何形容您现在感到的疲乏？您所感到的疲乏有多大程度是？',
            options:['0（没有破坏性的）','1','2','3','4','5','6','7','8','9','10（有破坏性的）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'12. 您如何形容您现在感到的疲乏？您所感到的疲乏有多大程度是？',
            options:['0（正面的）','1','2','3','4','5','6','7','8','9','10（负面的）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'13. 您如何形容您现在感到的疲乏？您所感到的疲乏有多大程度是？',
            options:['0（正常的）','1','2','3','4','5','6','7','8','9','10（异常的）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'14. 您现在有多大程度感到？',
            options:['0（躯体强壮）','1','2','3','4','5','6','7','8','9','10（躯体虚弱）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'15. 您现在有多大程度感到？',
            options:['0（清醒）','1','2','3','4','5','6','7','8','9','10（有睡意）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'16. 您现在有多大程度感到？',
            options:['0（有冲劲）','1','2','3','4','5','6','7','8','9','10（懒洋洋）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'17. 您现在有多大程度感到？',
            options:['0（有精神）','1','2','3','4','5','6','7','8','9','10（疲惫）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'18. 您现在有多大程度感到？',
            options:['0（有活力）','1','2','3','4','5','6','7','8','9','10（无活力）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'19. 您现在有多大程度感到？',
            options:['0（有耐性）','1','2','3','4','5','6','7','8','9','10（不耐烦）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'20. 您现在有多大程度感到？',
            options:['0（轻松）','1','2','3','4','5','6','7','8','9','10（紧张）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'21. 您现在有多大程度感到？',
            options:['0（开心）','1','2','3','4','5','6','7','8','9','10（抑郁）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'22. 您现在有多大程度感到？',
            options:['0（能够集中精神）','1','2','3','4','5','6','7','8','9','10（难以集中精神）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'23. 您现在有多大程度感到？',
            options:['0（记忆力良好）','1','2','3','4','5','6','7','8','9','10（记忆力很差）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple: false,
            title:'24. 您现在有多大程度感到？',
            options:['0（能够清晰地思考）','1','2','3','4','5','6','7','8','9','10（不能清晰地思考）'],
            type: "select",
            anwser: []
        },
        {
            isMultiple:false,
            title:'请填写您的姓名',
            options:[],
            type: "input",
            anwser: []
        },
    ]
}
