<template>
    <div class="survey-box">

        <!-- 问卷首页 -->
        <div v-if="surveyStatus == 'home'" class="survey-home">
            <div class="title">
                <p>秦皇岛市第一医院心脏康复筛查量表</p>
            </div>
            <div class="start-btn" @click="start">开始填写</div>
        </div>

        <!-- 问卷答题步骤 -->
        <div v-if="surveyStatus == 'content'" class="survey-content">
            <div class="survey-content-container">
                <div class="question-header">
                    <span class="question-index">{{ currentQuestionIndex + 1 }}</span><span class="question-total">/{{ surveyQuestions.length }}</span>
                </div>
                <div class="question-content">
                    <div class="question-title">{{ surveyQuestions[currentQuestionIndex].title }} <span v-if="surveyQuestions[currentQuestionIndex].isMultiple">(多选)</span></div>
                    <div class="question-option-box">
                        <template v-if="surveyQuestions[currentQuestionIndex].type == 'select'">
                            <div
                                class="question-option-item"
                                :class="{'question-option-item-active': surveyQuestions[currentQuestionIndex].anwser.includes(index)}"
                                v-for="(item, index) in surveyQuestions[currentQuestionIndex].options"
                                :key="index"
                                @click="selectOption(index)"
                            >
                                {{ optionsEnum[index] }} : {{ item }}
                            </div>
                        </template>

                        <template v-if="surveyQuestions[currentQuestionIndex].type == 'input'">
                            <textarea class="question-input" rows="4" placeholder="请输入..." v-model="surveyQuestions[currentQuestionIndex].anwser[0]"></textarea>
                        </template>

                    </div>
                </div>
                <div class="question-btn" @click="next">{{ (currentQuestionIndex < surveyQuestions.length - 1) ? "下一题" : "完成" }}</div>
            </div>
        </div>

    </div>
</template>

<script>
import surveyData from './json/data.js'

export default {
    name: 'App',
    data(){
        return {
            surveyStatus: "home",                   // home首页，content答题页
            currentQuestionIndex: 0,
            surveyQuestions: surveyData.DATA,
            optionsEnum: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q'],
        }
    },
    methods: {
        start(){
            this.surveyStatus = "content";
        },
        selectOption(index){
            let currentQuestion = this.surveyQuestions[this.currentQuestionIndex];
            if(currentQuestion.isMultiple){
                if(currentQuestion.anwser.includes(index)){
                    let i = currentQuestion.anwser.indexOf(index);
                    if(i > -1){
                        currentQuestion.anwser.splice(i, 1)
                    }
                } else {
                    currentQuestion.anwser.push(index)
                }
            } else {
                currentQuestion.anwser = [index]
            }
            currentQuestion.anwser.sort();
        },
        next(){
            document.getElementsByClassName("question-content")[0].scrollTop = 0;
            if(this.surveyQuestions[this.currentQuestionIndex].anwser.length > 0){
                if(this.currentQuestionIndex >= this.surveyQuestions.length - 1){
                    let resultData = this.surveyQuestions.map(item => {
                        let tempItem = JSON.parse(JSON.stringify(item));
                        if(tempItem.type == "select"){
                            tempItem.anwser = tempItem.anwser.map(a => {
                                return this.optionsEnum[a];
                            })
                        }
                        return tempItem;
                    })
                    console.log(resultData);
                } else {
                    this.currentQuestionIndex++;
                }
            } else {
                alert("请填写选项~")
            }
        },
    }
}
</script>

<style lang="less" scoped>
.survey-box{
    background: url("./assets/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .survey-home{
        text-align: center;
        width:100%;
        height:100%;
        position: relative;
        .title{
            width: 80%;
            margin: 0 auto;
            font-size: 33px;
            font-weight: 400;
            color: #4879F4;
            line-height: 50px;
            text-align: center;
            font-weight: 600;
            padding-top: 100px;
        }
        .start-btn{
            width: 80%;
            height: 42px;
            background: #FFFFFF;
            box-shadow: 0px 3px 16px 0px #93CCEE;
            border-radius: 80px;
            font-size: 18px;
            font-weight: 400;
            color: #5AA4FF;
            line-height: 40px;
            text-align: center;
            position: absolute;
            bottom: 70px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .survey-content{
        width:100%;
        height:100%;
        position: relative;
        .survey-content-container{
            position: absolute;
            top: 56px;
            bottom: 50px;
            left: 26px;
            right: 26px;
            background: #FFFFFF;
            border-radius: 13px;
            padding-bottom: 80px;
            padding-top: 50px;
            box-sizing: border-box;

            .question-header{
                position: absolute;
                top: 20px;
                right: 15px;
                text-align: right;
                .question-index{
                    font-size: 20px;
                    font-weight: 700;
                    color: #727272;
                    line-height: 20px;
                    letter-spacing: 2px;
                }
                .question-total{
                    font-size: 14px;
                    font-weight: 700;
                    color: #727272;
                    line-height: 20px;
                    letter-spacing: 2px;
                }
            }
            .question-content{
                padding: 0 20px;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                overflow: auto;
                .question-title{
                    font-size: 16px;
                    font-weight: 500;
                    color: #363636;
                    line-height: 20px;
                    margin-bottom: 10px;
                }
                .question-option-box{
                    width: 100%;
                    .question-option-item{
                        width: 100%;
                        min-height: 40px;
                        padding: 10px;
                        box-sizing: border-box;
                        line-height: 20px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #727272;
                        background: #F3F3F3;
                        border-radius: 8px;
                        margin-bottom: 10px;
                        &.question-option-item-active{
                            background: rgba(90,164,255,0.1);
                            color: #5AA4FF;
                        }
                    }
                    .question-input{
                        border: 1px solid #ddd;
                        border-radius: 5px;
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                }
            }
            .question-btn{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;
                width: 240px;
                height: 40px;
                background: #5AA4FF;
                border-radius: 80px;
                font-size: 18px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
            }
        }
    }
}
</style>
